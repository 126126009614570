import React from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import root from "window-or-global";
import {onError} from "apollo-link-error";
import {BatchHttpLink} from "apollo-link-batch-http";
import RecursiveIterator from "recursive-iterator";
// import root from "window-or-global";
import {createUploadLink} from "./utils/apollo-upload-client";
import Layout from "./section/layout";

import "../style/index.scss";

const apolloState = root.__APOLLO_STATE__;//eslint-disable-line
// const cache = apolloState ? new InMemoryCache().restore(apolloState) : new InMemoryCache();

const errorHandler = onError((op) => {
  const {response, graphQLErrors, networkError} = op;
  console.log("error", {meta: {graphQLErrors, networkError}, response}); //eslint-disable-line
});
function shouldNotBatch({variables, getContext}) {
  const context = getContext();
  if (!root.File) {
    return false;
  }
  for (let {node} of new RecursiveIterator(variables)) {
    if (node instanceof root.File) {
      return true;
    }
  }
  return false || context.noBatch;
}

const api = `${process.env.BACKEND_URL}graphql.api`;

const link = ApolloLink.split(
  shouldNotBatch,
  createUploadLink({
    uri: api,
    credentials: "include",
  }),
  new BatchHttpLink({
    credentials: "include",
    uri: api,
    batchInterval: 100,
  })
);


const authLink = new ApolloLink((operation, forward) => {
  const token = root?.localStorage?.getItem("authToken");
  if(token) {
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  }
  return forward(operation);
});

function merge(existing, incoming) {
  return {
    ...existing,
    ...incoming,
  };
}

const cacheSettings = {
  typePolicies: {
    QueryClassMethods: {
      merge(existing = {}, incoming) {
        const output = {
          ...existing,
        };
        Object.keys(incoming).forEach((k) => {
          if (existing[k] && k !== "__typename") {
            output[k] = merge(existing[k], incoming[k]);
          } else {
            output[k] = incoming[k];
          }
        });
        return output;
      },
    },
    QueryModels: {
      merge(existing = {}, incoming) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
    MutationClassMethods: {
      merge(existing = {}, incoming) {
        const output = {
          ...existing,
        };
        Object.keys(incoming).forEach((k) => {
          if (existing[k] && k !== "__typename") {
            output[k] = merge(existing[k], incoming[k]);
          } else {
            output[k] = incoming[k];
          }
        });
        return output;
      },
    },
    MutationModels: {
      merge(existing = {}, incoming) {
        return {
          ...existing,
          ...incoming,
        };
      },
    },
  },
};
const cache = new InMemoryCache(cacheSettings);

export const client = new ApolloClient({
  credentials: "include",
  uri: api,
  shouldBatch: true,
  // cache: apolloState ? new InMemoryCache().restore(apolloState) : new InMemoryCache(),
  cache,
  link: errorHandler.concat(authLink.concat(link)),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      // errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      // errorPolicy: "all",
    },
    mutate: {
      // errorPolicy: "all",
    },
  },
});


export default function Manager(props) {
  return (
    <ApolloProvider client={client}>
      <Layout>
        {props.children}
      </Layout>
    </ApolloProvider>
  )
}